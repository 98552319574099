<template>
  <div class="itemAba wrapper-md" :class="{'is-loading': loading}" v-if="loading">
    Carregando formulário, por favor, aguarde...<!-- <a @click="loadForm">Tentar novamente</a>-->
  </div>
  <div class="itemAba wrapper-md" v-else>
    <div class="app-forms-container box-contato-simple m-t-n wrapper e-input-modern fin-form simple-btn" v-for="form in forms" :key="form.id">
      <div class="bens wrapper bg-blue-grey-1 b-radius-3px b m-b-xxl">
        <erp-s-field label="Selecionar Checklist do processo ou Bens relacionados: ">
          <erp-select :options="checklistOpts" v-model="bem" />
        </erp-s-field>
      </div>
      <div class="extra-print-header wrapper-lg text-center m-b-lg hide">
        <h3 style="font-size: 22px; margin-bottom: 0">Checklist Processo {{processo.numero|formataNumeroProcesso}}</h3>
        <h4 v-if="bem" style="font-size: 16px; margin-top: 4px">
          Bem ID {{bemSelect.id}} - {{bemSelect.descricao}}
        </h4>
      </div>
      <mount-form v-if="!bindForm" hide-name :form="form" :entity-name="entityNameComputed" :response-entity-id="entityIdComputed" />
    </div>
  </div>
</template>

<script>
import AppMixin from "@/components/pessoa/components/include/pessoa/apps/appMixin"
import {getForm} from "@/domain/form/services"
import MountForm from "@/components/forms/components/mount/Form"
import ErpSelect from "@/plugins/uloc-erp/components/form/select";
import {listBensProcesso} from "@/domain/processos/services";
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField";

export default {
  name: "ProcessoChecklist",
  components: {
    ErpSField,
    ErpSelect,
    MountForm
  },
  props: ['processo'],
  data () {
    return {
      entityName: 'processo-checklist',
      entityId: this.processo.id,
      loading: true,
      bens: [],
      bem: null,
      forms: null,
      bindForm: false
    }
  },
  mixins: [AppMixin],
  mounted() {
    this.loadForm()
  },
  watch: {
    bem (v) {
      this.$nextTick(async () => {
        this.bindForm = true
        await this.loadForm()
        this.$nextTick(() => {
          this.bindForm = false
        })
      })
    }
  },
  computed: {
    code () {
      if (this.bem) {
        return 'processo-checklist'
      }
      return null
    },
    entityNameComputed () {
      if (this.bem) {
        return 'bem-processo'
      }
      return this.entityName
    },
    entityIdComputed () {
      if (this.bem) {
        return this.bem
      }
      return this.entityId
    },
    checklistOpts () {
      const list = []
      list.push({
        label: 'Checklist do Processo',
        value: null
      })
      if (this.bens && this.bens.length) {
        this.bens.map(bem => {
          list.push({
            label: 'ID ' + bem.id + ' - ' + bem.descricao,
            value: bem.id
          })
        })
      }
      return list
    },
    bemSelect () {
      return this.bens.find(b => b.id === this.bem)
    }
  },
  methods: {
    async listBens () {
      return await listBensProcesso(this.processo.id)
          .then(response => {
            console.log(response.dat)
            this.bens = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    async loadForm () {
      this.loading = true
      await this.listBens()
      getForm(null, this.entityNameComputed, null, this.code, this.entityIdComputed)
          .then(response => {
            console.log(response)
            this.forms = response.data.forms
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>

